import { computed } from "vue";
import { set as setItem } from "lodash";

export function useCountdownTimer(timer) {
  // Computed (Two-way)
  const timeoutTitle = computed({
    get() {
      return timer.timeoutTitle;
    },
    set(newValue) {
      setItem(timer, "timeoutTitle", newValue);
    },
  });

  const countdownHours = computed({
    get() {
      return timer.countdownHours;
    },
    set(newValue) {
      setItem(timer, "countdownHours", newValue);
    },
  });

  const countdownMinutes = computed({
    get() {
      return timer.countdownMinutes;
    },
    set(newValue) {
      setItem(timer, "countdownMinutes", newValue);
    },
  });

  const countdownSeconds = computed({
    get() {
      return timer.countdownSeconds;
    },
    set(newValue) {
      setItem(timer, "countdownSeconds", newValue);
    },
  });

  const startTimerPage = computed({
    get() {
      return timer.startTimerPageId;
    },
    set(newValue) {
      setItem(timer, "startTimerPageId", newValue);
    },
  });
  return {
    timeoutTitle,
    countdownHours,
    countdownMinutes,
    countdownSeconds,
    startTimerPage,
  };
}
