<template>
  <div class="d-flex w-100">
    <img
      v-if="logoImage && !inFormLogo"
      :src="logoImage"
      class="form-logo"
      alt="Brand Logo"
      :style="logoStyling"
    />
  </div>
</template>

<script setup>
import { computed } from "vue";
const props = defineProps({
  logoImage: {
    type: String,
    default: "",
  },
  logoBoxColor: {
    type: String,
    required: true,
  },
  logoSize: {
    type: Number,
    required: true,
  },
  inFormLogo: {
    type: Boolean,
    required: true,
  },
});

//computed

const logoStyling = computed(() => {
  return {
    background: props.logoBoxColor || "#00000000",
    padding: "5px",
    borderRadius: "5px",
    height: `${props.logoSize || 60}px`,
  };
});
</script>

<style scoped>
.form-logo {
  z-index: 2;
  margin: 20px;
}
</style>
